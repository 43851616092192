<div id="card-container">
  <div class="card-section">
    <div class="card-logo">
      <img alt="logo" class="logo_image" src="../../../assets/images/Pipelinedl-ogts_logo.png">
    </div>
    <div class="card-title">
      You have been logged out of the MRO Portal
    </div>
    <!-- <div class="card-content">
      <button class="adb2c-loginbutton" (click)="userAuth()">Login</button>
    </div> -->
  </div>
</div>
